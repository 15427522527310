import '../utils/setupEmployeeApp';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Nav from '../apps/shared/components/Nav';

function main() {
  const node = document.getElementById('nav-app-container');
  const root = createRoot(node);
  root.render(<Nav />);
}

main();
