const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const isPopUpInput = (el) => {
  const tag = el.tagName.toLowerCase();
  return (
    tag === 'select' ||
    tag === 'textarea' ||
    (tag === 'input' &&
      el.type !== 'radio' &&
      el.type !== 'checkbox' &&
      el.type !== 'button' &&
      el.type !== 'submit')
  );
};

const handleFocusIn = (evt) => {
  if (isPopUpInput(evt.target)) {
    document.body.classList.add('with-focus');
  }
};

const handleFocusOut = () => {
  // XXX: I hate this, but it prevents an issue where clicking on <PrimaryButton />
  // while the keyboard is up causes .with-focus to be removed before the tap
  // processes as a click, so the button moves before it's clicked.
  setTimeout(() => {
    document.body.classList.remove('with-focus');
  });
};

/**
 * This adds a `.with-focus` class to the body when any element is focused that
 * causes a popover in mobile safari, such as a text input, textarea, or select.
 * This allows us to style things differently when the page is partially
 * obscured (for example, <PrimaryButton /> becomes unstuck from the bottom of
 * the page).
 */
export default function iOSFocusCapture() {
  if (!iOS) {
    return;
  }

  document.body.addEventListener('focusin', handleFocusIn);
  document.body.addEventListener('focusout', handleFocusOut);
}
